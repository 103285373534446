import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { GeneralConfirmDialog, GeneralConfirmDialogButton } from 'src/app/shared/dialogs/general-confirm-dialog/general-confirm-dialog.model';
import { GeneralConfirmDialogComponent } from 'src/app/shared/dialogs/general-confirm-dialog/general-confirm-dialog.component';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { isMobile } from '@futura/futura-ui/breakpoint';

export function getAfterClosed<T, R>(ref: MatDialogRef<T, R> | MatBottomSheetRef<T, R>) {
  if (ref instanceof MatDialogRef) return ref.afterClosed();
  else return ref.afterDismissed();
}

export interface FutDialogConfig<D> {
  dimension: 'lg' | 'md' | 'sm';
  showOverflow?: boolean;
  showLoginBackground?: boolean;
  matConf?: MatDialogConfig<D>;
}

@Injectable({
  providedIn: 'root',
})
export class FutDialogService {
  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet
  ) {}

  public get openDialogs(): MatDialogRef<unknown>[] {
    return this.dialog.openDialogs;
  }

  public openDialogOrBottomSheet<T, D = unknown | undefined | null, R = unknown | undefined | null>(
    template: ComponentType<T>,
    config?: FutDialogConfig<D>
  ): MatDialogRef<T, R> | MatBottomSheetRef<T, R> {
    const dim = window.innerWidth;
    if (isMobile(dim))
      return this.bottomSheet.open(template, {
        data: config?.matConf?.data,
        panelClass: 'fut-bottom-sheet',
      });
    else return this.dialog.open(template, this.buildConf(config));
  }

  public open<T, D = unknown | undefined | null, R = unknown | undefined | null>(
    template: ComponentType<T> | TemplateRef<T>,
    config?: FutDialogConfig<D>
  ): MatDialogRef<T, R> {
    return this.dialog.open<T, D, R>(template, this.buildConf(config));
  }

  public confirmDialog<T = unknown>(
    data: {
      title: string;
      subtitle?: string;
      icon?: string;
      content?: string;
      buttonsConfig: {
        buttons: Array<GeneralConfirmDialogButton>;
        align: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly';
      };
    },
    functions?: {
      onConfirm?: (result?: T) => void;
      onCancel?: (result?: T) => void;
    },
    config?: FutDialogConfig<GeneralConfirmDialog>
  ): void {
    if (!config) config = { dimension: 'md' };
    config.matConf = config.matConf || {};

    if (!config.matConf.data) config.matConf.data = {} as GeneralConfirmDialog;

    this.open<
      GeneralConfirmDialogComponent,
      GeneralConfirmDialog,
      | {
          type: 'confirm' | 'cancel';
          extras?: T;
        }
      | undefined
    >(GeneralConfirmDialogComponent, {
      dimension: 'md',
      matConf: { data },
    })
      .afterClosed()
      .subscribe(result => {
        if (result === undefined) functions?.onCancel?.();
        else if (result.type === 'confirm') functions?.onConfirm?.(result.extras);
        else functions?.onCancel?.(result.extras);
      });
  }

  private buildConf<D>(conf: FutDialogConfig<D> = { dimension: 'md' }): MatDialogConfig<D> {
    const classes = [`fut-dialog-${conf.dimension}`];
    const backdropClasses = [];
    if (!conf.showOverflow) {
      classes.push('overflow-hidden');
    }
    const matConf = conf.matConf || {};
    const panelClass: string | string[] | undefined = matConf.panelClass;
    const backdropClass: string | string[] | undefined = matConf.backdropClass;

    if (conf.showLoginBackground) {
      backdropClasses.push('onboarding-dialogs');
    }

    if (panelClass) {
      if (typeof panelClass === 'string') {
        classes.push(panelClass);
      } else {
        classes.push(...panelClass);
      }
    }

    if (backdropClass) {
      if (typeof backdropClass === 'string') {
        backdropClasses.push(backdropClass);
      } else {
        backdropClasses.push(...backdropClass);
      }
    }

    matConf.panelClass = classes;
    matConf.backdropClass = backdropClasses;
    return matConf;
  }
}
